var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (!_vm.unlock)?_c('div',{staticClass:"d-flex flex-wrap gap"},[_c('filter-menu',{key:_vm.targetSearch.documentType + 'menu',attrs:{"data-cy":"search-filter-menu","filter":_vm.targetSearch.filterSet,"auto-filter-type":_vm.autoFilterType,"consolidable":_vm.consolidable,"show-pin":"","start-with-selected-only-mode":""},on:{"setConsolidated":function($event){return _vm.setConsolidated($event)}},scopedSlots:_vm._u([{key:"pins",fn:function(ref){
var item = ref.item;
return [_vm._t("pins",null,{"item":item})]}}],null,true)}),_vm._l((_vm.visibleCats),function(category){return _c('filter-menu',{key:category.id,attrs:{"data-cy":"pinned-category","filter":_vm.targetSearch.filterSet,"category":category,"target-search":_vm.targetSearch},on:{"clearSelection":function($event){return _vm.clearSelection(category)}},scopedSlots:_vm._u([{key:"pins-category",fn:function(ref){
var item = ref.item;
var isSelected = ref.isSelected;
return [(_vm.unlock)?_vm._t("pins-category",null,{"item":item,"isSelected":isSelected}):_vm._e()]}}],null,true)})})],2):_c('div',{staticClass:"d-flex flex-wrap gap"},[_c('draggable',{staticClass:"display-contents",attrs:{"draggable":".draggable"},on:{"end":_vm.dataCatsPinnedEmit},model:{value:(_vm.computedCatsPinned),callback:function ($$v) {_vm.computedCatsPinned=$$v},expression:"computedCatsPinned"}},_vm._l((_vm.computedCatsPinned),function(item){return _c('pinned-filter',{key:item,staticClass:"draggable",attrs:{"data-cy":"pinned-category","val-item":item},scopedSlots:_vm._u([{key:"pins",fn:function(ref){
var item = ref.item;
return [_vm._t("pins",null,{"item":item})]}}],null,true)})}),1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }