<template>
  <div
    class="d-flex flex-wrap gap"
    v-if="!unlock"
  >
    <filter-menu
      data-cy="search-filter-menu"
      :key="targetSearch.documentType + 'menu'"
      :filter="targetSearch.filterSet"
      :auto-filter-type="autoFilterType"
      :consolidable="consolidable"
      @setConsolidated="setConsolidated($event)"
      show-pin
      start-with-selected-only-mode
    >
      <template v-slot:pins="{item}">
        <slot
          name="pins"
          :item="item"
        />
      </template>
    </filter-menu>
    <filter-menu
      data-cy="pinned-category"
      v-for="category in visibleCats"
      :key="category.id"
      :filter="targetSearch.filterSet"
      :category="category"
      :target-search="targetSearch"
      @clearSelection="clearSelection(category)"
    >
      <template v-slot:pins-category="{item, isSelected}">
        <slot
          v-if="unlock"
          name="pins-category"
          :item="item"
          :isSelected="isSelected"
        />
      </template>
    </filter-menu>
  </div>
  <div
    v-else
    class="d-flex flex-wrap gap"
  >
    <draggable
      v-model="computedCatsPinned"
      draggable=".draggable"
      @end="dataCatsPinnedEmit"
      class="display-contents"
    >
      <pinned-filter
        data-cy="pinned-category"
        v-for="item in computedCatsPinned"
        :key="item"
        :val-item="item"
        class="draggable"
      >
        <template v-slot:pins="{item}">
          <slot
            name="pins"
            :item="item"
          />
        </template>
      </pinned-filter>
    </draggable>
  </div>
</template>

<script>
import Search from '@/pages/search/controllers'

export default {
  components: {
    Draggable: () => import('vuedraggable'),
    FilterMenu: () => import('./filter-menu'),
    PinnedFilter: () => import('./pinned-filter')
  },
  computed: {
    computedCatsPinned: {
      get () {
        return this.dataCatsPinned
      },
      set (v) {
        this.dataCatsPinned = v
        this.dataCatsPinnedEmit()
      }
    }
  },
  data () {
    return {
      filterParamLoading: false,
      dataDocumentEmit: this.$nextTickDedup(this.emitDocument),
      dataCatsPinnedEmit: this.$nextTickDedup(this.emitCatsPinned),
      dataCatsPinned: null
    }
  },
  methods: {
    emitDocument () {
      this.targetSearch.setFilterSet(this.targetSearch.filterSet).execute()
    },
    emitCatsPinned () {
      if (!this.lodash.isEqual(this.catsPinned, this.dataCatsPinned)) {
        this.$emit('update:cats-pinned', this.lodash.cloneDeep(this.dataCatsPinned))
      }
    },
    clearSelection (category) {
      this.targetSearch.filterSet.clearSelectedValues(category)
      this.dataDocumentEmit()
    },
    incExcSwitchValue (item) {
      return this.targetSearch.filterSet.incExcSwitchValue(item)
    },
    async setSelectedValues (item, incExcSwitchValue) {
      await this.targetSearch.filterSet.updateSelectedValues(item, incExcSwitchValue, true)
      this.dataDocumentEmit()
    },
    setConsolidated (v) {
      if (this.consolidable) {
        this.targetSearch.filterSet.setConsolidated(v)
        this.dataDocumentEmit()
      }
    }
  },
  watch: {
    catsPinned: {
      immediate: true,
      handler (v) {
        this.dataCatsPinned = this.lodash.cloneDeep(v)
        this.dataCatsPinnedEmit()
      }
    }
  },
  props: {
    autoFilterType: String,
    catsPinned: Array,
    visibleCats: Array,
    consolidable: Boolean,
    unlock: Boolean,
    targetSearch: Search
  }
}
</script>

<style lang="stylus" scoped>
.display-contents
  display contents

.draggable
  cursor move !important
</style>
